import * as Types from "./types";


export const addServicesToCart = ( service ) => ( dispatch, getState ) => {
    const branchDetails = JSON.parse(localStorage.getItem("branchdetails"));
    // console.log(service.amount_charged.toString());
    const { 
        amount_charged, 
        service_name, 
        id: service_id, 
        quantity,
        merchant_id, 
        image_url 
    } = service;

    const cartItems = {
        id: service.id,
        service_id: service_id,
        service_name: service_name,
        amount: amount_charged,
        quantity,
        picture: image_url,
        product_type: "service",
        merchant_id,
        merchant_loyalty_program_id: service.loyalty_id ? service.loyalty_id : null,
        merchant_product_category_id: null,
        name: service.service_name ? service.service_name : service.name,
        price: amount_charged ? amount_charged.toString() : service.price.toString(),
        tax: null,
        tax_rate: null,
        tax_type: null,
        track_inventory: false,
        branch_id: branchDetails?.branch_id,
        variants: []
    }

    dispatch({ type: Types.ADD_TO_CART, payload: cartItems});

}

export const addToCart = ( cart ) =>( dispatch, getState )=> {
    // console.log("cart", cart);
    const merchantDetails = JSON.parse(localStorage.getItem("merchant_details"));
    const branchDetails = JSON.parse(localStorage.getItem("branchdetails"));
    const cart_items = JSON.parse(localStorage.getItem("urewards-carts"))
    const new_cart = cart_items && cart_items.find((items)=>{
        return items.merchant_id !== merchantDetails.id
    });
    if(new_cart){
        dispatch({ type: Types.CLEAR_CART})
    }
    // localStorage.setItem("urewards-carts", JSON.stringify(new_cart))
    const {
        merchant_id,
        name,
        picture,
        price,
        id, 
        description,
        tax,
        tax_rate,
        tax_type,
        track_inventory,
        merchant_loyalty_program_id,
        merchant_product_category_id, 
        variants,
        custom_quantities,
        // products,
        quantity: bundle_quantity,
        bundle_id,
        weight
    } = cart.product;
    const quantity = cart.quantity;
    const totalQuantity = cart.totalProductCount;
    let amount;
    if(variants && variants.length > 0){
        amount = quantity * Number(variants[0].price);
    }else if(custom_quantities && custom_quantities.length > 0){
        amount = quantity * Number(custom_quantities[0].price);
    }else{
        amount = quantity * Number(price);
    } 

    const cartItems = {
        merchant_id,
        name,
        picture,
        quantity,
        price,
        id, 
        service_id: null,
        service_name: null,
        description,
        product_type: "product",
        product_id: id,
        amount: amount,
        total_quantity: Number(totalQuantity),
        branch_id: branchDetails?.branch_id,
        tax,
        tax_rate,
        tax_type,
        track_inventory,
        merchant_loyalty_program_id,
        merchant_product_category_id,
        variants: cart.variant ? [cart.variant] : variants,
        custom_quantities: cart.custom_quantities ? [cart.custom_quantities] : custom_quantities,
        // products,
        bundle_quantity,
        bundle_id: bundle_id ? bundle_id : null,
        weight
    }

    // console.log(cartItems);
    dispatch({ type: Types.ADD_TO_CART, payload: cartItems});
    
}

export const removeFromCart = ( cart ) =>( dispatch, getState )=> {
    dispatch({ type: Types.REMOVE_FROM_CART, payload: cart});
}

export const AddInstoreOrders =  ( createOrder ) =>( dispatch, getState ) => {
    
    const merchantDetails = JSON.parse(localStorage.getItem("merchant_details"));
    const branchDetails = JSON.parse(localStorage.getItem("branchdetails"));
    // console.log(createOrder.orderID);
    let orderId = localStorage.getItem("urewards_orderid") ? JSON.parse(localStorage.getItem("urewards_orderid")) : 1;
    let orderID;
    orderId = orderId + 1;
    dispatch({ type: Types.CREATE_ID, payload: orderId });
		
        if(createOrder.orderID > 9999){
            if(orderId < 10){
                orderID = "000" + orderId;
            }else if(orderId < 100){
                orderID = "00" + orderId;
            }else if(orderId < 1000){
                orderID = "0" + orderId;
            }else{
                orderID = "" + orderId;
            }
        }else{
            if(createOrder.orderID < 10){
                orderID = "000" + createOrder.orderID;
            }else if(createOrder.orderID < 100){
                orderID = "00" + createOrder.orderID;
            }else if(createOrder.orderID < 1000){
                orderID = "0" + createOrder.orderID;
            }else{
                orderID = "" + orderId;
            }
        }

   const id = `ud_is${Math.floor(Math.random() * 10000000000)}`;
    const order = {
        orderID: id,
        order_id: orderID,
        merchant_id: merchantDetails && merchantDetails.id,
        branch_id: branchDetails?.branch_id,
        merchant_phone_number: merchantDetails && merchantDetails?.contact_number,
        merchant_email: merchantDetails && merchantDetails?.email,
        merchant_name: merchantDetails && merchantDetails?.business_name,
        orderNotes: createOrder.deliveryDetails.orderNotes,
        customer: {
            user_id: Number(createOrder.user.user_id),
            first_name: createOrder.user.first_name,
            last_name: createOrder.user.last_name,
            phone_number: createOrder.user.phone_number,
            email: createOrder.deliveryDetails.email,
            sex: createOrder.user.sex,
            address: createOrder.user.address_line1,
            delivery_address: createOrder.deliveryDetails.deliveryAddress
        },
        order_items: createOrder.carts,
        created_at: new Date(),
        status: "pending",
        processed_by: "",
        reference_code: "",
        order_type: "in-store",
        paid_at: new Date(),
        paid_time: new Date(),
        expiry_date: new Date(),
        total: createOrder.total
    }

    localStorage.setItem("ureward_orderDetails", JSON.stringify(order))

    dispatch({ type: Types.ORDER_SUCCESS, payload: order});
    dispatch({ type: Types.MERCHANT_ORDER_DETAILS, payload: merchantDetails});
    
} 

export const AddInstoreUSSDOrders =  ( createOrder ) =>( dispatch, getState ) => {
    
    const merchantDetails = JSON.parse(localStorage.getItem("merchant_details"));
    const branchDetails = JSON.parse(localStorage.getItem("branchdetails"));
    // console.log(createOrder.orderID);
    let orderId = localStorage.getItem("urewards_orderid") ? JSON.parse(localStorage.getItem("urewards_orderid")) : 1;
    let orderID;
    
    
    orderId = orderId + 1;
    dispatch({ type: Types.CREATE_ID, payload: orderId });
		
        if(createOrder.orderID > 9999){
            if(orderId < 10){
                orderID = "000" + orderId;
            }else if(orderId < 100){
                orderID = "00" + orderId;
            }else if(orderId < 1000){
                orderID = "0" + orderId;
            }else{
                orderID = "" + orderId;
            }
        }else{
            if(createOrder.orderID < 10){
                orderID = "000" + createOrder.orderID;
            }else if(createOrder.orderID < 100){
                orderID = "00" + createOrder.orderID;
            }else if(createOrder.orderID < 1000){
                orderID = "0" + createOrder.orderID;
            }else{
                orderID = "" + orderId;
            }
        }
   const id = `ud_is${Math.floor(Math.random() * 10000000000)}`;
    const order = {
        orderID: id,
        order_id: orderID,
        merchant_id: merchantDetails && merchantDetails.id,
        branch_id: branchDetails?.branch_id,
        merchant_phone_number: merchantDetails && merchantDetails?.contact_number,
        merchant_email: merchantDetails && merchantDetails?.email,
        merchant_name: merchantDetails && merchantDetails?.business_name,
        orderNotes: createOrder.deliveryDetails.orderNotes,
        customer: {
            user_id: Number(createOrder.user.user_id),
            first_name: createOrder.user.first_name,
            last_name: createOrder.user.last_name,
            phone_number: createOrder.user.phone_number,
            email: createOrder.deliveryDetails.email,
            sex: createOrder.user.sex,
            address: createOrder.user.address_line1,
            delivery_address: createOrder.deliveryDetails.deliveryAddress
        },
        order_items: createOrder.carts,
        created_at: new Date(),
        status: "paid",
        processed_by: "",
        reference_code: "",
        order_type: "in-store",
        paid_at: new Date(),
        paid_time: new Date(),
        expiry_date: new Date(),
        total: createOrder.total
    }

    localStorage.setItem("ureward_orderDetails", JSON.stringify(order))
    dispatch({ type: Types.ORDER_SUCCESS, payload: order});
    dispatch({ type: Types.MERCHANT_ORDER_DETAILS, payload: merchantDetails});
    
} 

export const getMerchantByDispatch = () => ( dispatch ) =>{

    const merchantDetails = JSON.parse(localStorage.getItem("merchant_details"));

    dispatch({ type: Types.MERCHANT_ORDER_DETAILS, payload: merchantDetails});

}

export const clearCart = ( ) => ( dispatch ) =>{
    
    dispatch({ type: Types.CLEAR_CART })
}

export const createID = ( ) => ( dispatch ) =>{

    const id = id + 1
    
    dispatch({ type: Types.CREATE_ID })
}

export const reSignInLoading = () => ( dispatch ) =>{
    dispatch({ type: Types.RESIGNIN_LOADING })
}

export const reSignInSuccess = ( merchant_id) => ( dispatch )=>{
    localStorage.setItem("merchant_id_array", JSON.stringify(merchant_id));

    dispatch({ type: Types.RESIGNIN_SUCCESS, payload:  merchant_id });
}
